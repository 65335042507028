
.question-block {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    color: white;
    width: 500px;
    border-radius: 8px;
    padding-top: 5px;
    cursor: default;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 100px;
    margin-right: 100px;
    z-index: 50 !important;
}

.anne {
    left: 0;
    margin-left: 100px;
}

.question {
    background-color: white !important;
    color: black !important;
    margin-top: 10px;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 25px;
}

.question p {
    padding-top: 5px;
    padding-bottom: 5px;
}

.clicked {
    background-color: rgba(84, 84, 84, 0.70) !important;
    color: rgba(39,39,39, 0.74) !important;
}