

.mapButton {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5;
    width: 50px;
  }
  
  .leftArrow {
    position: absolute;
    bottom: 50%;
    left: 0;
    z-index: 5;
    width: 50px;
    margin-left: 20px;
  }
  
  .rightArrow {
    position: absolute;
    bottom: 50%;
    right: 0;
    z-index: 5;
    width: 50px;
    margin-right: 20px;
  }

  .back {
    top: 0 !important;
    position: absolute;
    left: 0;
    z-index: 5;
    margin-left: 20px;
    text-decoration: underline;
    cursor: default;
  }