.modal {
    position: fixed;
    background-color: black;
    color: white;
    width: 800px;
    z-index: 2;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 12px;
    display: flex;
    z-index: 100;
}

.chez .value {
    margin-top: 20px;
}

.short .value {
    width: 400px;
    margin-top: 40px;
}

.obelisk {
    height: 400px;
}

.obelisk .value {
    margin-top: 20px;
}

.evidence {
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 500px !important;
}

.image-wrapper {
    overflow: hidden;
    width: 450px;
}

.modal-image {
    width: 100%;
    padding: 20px;
    position: relative;
    z-index: 1 !important;
    top: 50%;
    transform: translateY(-50%);
}

.info {
    width: 50%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 30px;
}

.title-evidence {
    margin-left: 30px;
    margin-top: 30px;
    margin-bottom: 0;
}

.note-evidence {
    margin-left: 30px;
}

.modal-image-evidence {
    width: auto;
    height: 450px;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.closeButton {
    position: absolute;
    width: 15px;
    height: 15px;
    margin-left: 350px;
    margin-top: -10px !important;
    border-radius: 0;
}

.closeButton-evidence {
    position: absolute;
    width: 15px;
    height: 15px;
    margin-left: 465px;
    margin-top: 20px;
}

.note {
    margin-top: -20px;
}

.value {
    position: relative;
    background-color: #AD1E1E;
    height: 30px;
    width: 300px;
    border-radius: 4px;
    margin-left: -100px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 75px;
    z-index: 100;
}

.value h4 {
    padding-top: 5px;
}