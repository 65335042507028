body { margin: 0; }

#bridge {
	display: block;
	margin: 0 auto;
  background-image: url('./images/suspect-reveal.png');
	width: 52vw !important;
	height: 58vh !important;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: 52vw 58vh;
  cursor: url('./images/pencil.png'), auto !important;
}

#bridgeContainer { 
  text-align: center;
  z-index: 100;
  display: block;
}

.police-sketch-page {
  background-image: url('./images/police-sketches.jpg');
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.sketchButton {
  margin-top: 300px !important;
}

.sketch-text {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;
}