
@font-face {
  font-family: 'Typewriter';
  font-style: normal;
  font-weight: normal;
  src: url('/public/fonts/JMH\ Typewriter.ttf'); /* IE9 Compat Modes */
  src: local('Typewriter'), local('Typewriter'),
       url('/public/fonts/JMH\ Typewriter.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Typewriter';
  font-style: normal;
  font-weight: bold;
  src: url('/public/fonts/JMH\ Typewriter-Bold.ttf'); /* IE9 Compat Modes */
  src: local('Typewriter'), local('Typewriter'),
       url('/public/fonts/JMH\ Typewriter-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Typewriter';
  font-style: normal;
  font-weight: thin;
  src: url('/public/fonts/JMH\ Typewriter-Thin.ttf'); /* IE9 Compat Modes */
  src: local('Typewriter'), local('Typewriter'),
       url('/public/fonts/JMH\ Typewriter-Thin.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Typewriter';
  font-style: normal;
  font-weight: black;
  src: url('/public/fonts/JMH\ Typewriter-Black.ttf'); /* IE9 Compat Modes */
  src: local('Typewriter'), local('Typewriter'),
       url('/public/fonts/JMH\ Typewriter-Black.ttf') format('truetype'), /* Safari, Android, iOS */
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-page {
  background-image: url('./images/homescreen.png');
  background-attachment: fixed !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.exit-modal {
  position: fixed;
  background-color: white;
  color: black;
  width: 550px;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 12px;
  display: flex;
  z-index: 100;
  flex-direction: column;
  text-align: center;
  padding: 50px;
}

.modal-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.exit-closeButton {
  position: absolute;
  width: 15px;
  height: 15px;
  margin-left: 500px;
  margin-top: 20px !important;
  border-radius: 0;
}

.button-group {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-around;
}

.exit-button-2 {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  margin-bottom: 50px;
  margin-right: 50px;
}

.about-page-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-frame {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.instructions-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-frame-1 {
  display: block;
  margin: auto;
  padding-top: 9vh;
}

.about-frame-2 p {
  margin-top: -130px;
  width: 150px;
  margin-left: 20px;
}

.about-frame-3 {
  margin-top: 200px;
}

.about-frame-5 {
  margin-top: 50px;
}

.about-frame-3 img {
  width: 300px;
}

.about-frame-4 img {
  width: 270px;
}

.about-frame-5 img {
  width: 400px;
}

.about-frame-3 p {
  margin-top: -160px;
  width: 250px;
  margin-left: 25px;
}

.about-frame-4 p {
  margin-top: -245px;
  width: 150px;
  margin-left: 60px;
}

.about-frame-5 p {
  margin-top: -200px;
  width: 300px;
  margin-left: 50px;
  color: black;
}

.tunnel-page {
  cursor: url('./images/flashlight.png'), auto !important;
}

.about-text-1 {
  width: 350px;
  color: white;
  display: block;
  margin: auto;
  margin-top: -300px;
  text-align: center;
}

.about-text-2 {
  width: 350px;
  color: white;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 175px;
}

.about-text-3 {
  width: 350px;
  color: white;
  display: block;
  margin: auto;
  text-align: center;
  margin-top: 200px;

  font-size: 25px;
}

.about-carrot {
  width: 20px;
  display: block;
  margin: auto;
  margin-top: 20px;
}

.about-frame-6 img {
  padding-top: 300px;
  display: block;
  margin: auto;
}

.about-page-end {
  padding-bottom: 50vh;
}

.about-frame-6 {
  padding-bottom: 150px;
}

.about-paragraph {
  margin-top: 100px !important;
  width: 600px;
  text-align: center;
  margin-bottom: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 20px;
}

.liam {
  color: black !important;
}

.actors {
  text-align: center;
  margin-top: -410px;
}

.frames {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 100px;
  text-align: center;
}

.bio {
  display: flex;
  flex-direction: column;
  margin-top: -50px !important;
}

body {
  font-family: 'Typewriter';
}

button {
  font-family: 'Typewriter';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.parent {
  position: relative;
  top: 0;
  left: 0;
}

.childImage {
  position: absolute;
}

.caption-block {
  margin-top: -25px;
  background-color: white;
  color: black;
  font-size: 30 !important;
  height: 80px;
  width: 500px;
  padding-left: 10px;
  z-index: 1;
  padding-top: 10px;
  box-shadow: -10px 10px #7B7B7B;
  border-radius: 6px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: black;
  outline-offset: 0;
}

.caption-text {
  margin-top: 0 !important;
  margin-bottom: -8px !important;
}

.speaker {
  font-size: 30 !important;
  color: black;
  background-color: white;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  margin-left: 15px;
  z-index: 5;
  font-weight: bold !important;
  border-radius: 6px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: black;
  outline-offset: 0;
  width: -moz-fit-content;
  width: fit-content;
}

.captionButton {
  width: 50px;
  height: 50px;
  margin-left: 30px;
  border-radius: 50%;
  margin-top: 55px;
  background-color: white;
}

.caption-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.screenSizeWarning {
  height: 100vh;
  background-color: black;
  width: 100vw;
  position: absolute;
  padding: 30px;
  z-index: 1000;
}

.about-button {
  color: white;
  position: absolute;
  top: 30px;
  right: 45px;
  text-decoration: underline;
  cursor: default;
}

.about-page-button {
  display: block;
  margin: auto;
}

@media only screen and (min-width: 1300px) {
  .screenSizeWarning {
      display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .about-page {
      display: none;
  }
}

.styledButton {
  margin-top: 30px;
  height: 40px;
  width: 200px;
  box-shadow: -6px 6px #7B7B7B;
  border-radius: 6px;
  outline-width: 2px;
  outline-style: solid;
  outline-color: black;
  outline-offset: 0;
  z-index: 100;
}

.secondaryButton {
  background-color: #ADADAD !important;
  outline-style: solid;
  outline-color: black;
  outline-offset: 0;
  margin-top: 30px;
  height: 40px;
  width: 200px;
  border-radius: 6px;
  position: absolute;
  top: 10px;
  right: 30px;
}

.phone-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin-left: 50%;
  margin-top: 10%;
}

.buttonCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px !important;
  margin-left: -100px !important;
}

.instructions-button {
  top: 70% !important;
}

.buttonBottom {
  position: absolute;
  top: 80%;
  left: 50%;
  margin-top: -20px !important;
  margin-left: -100px !important;
}

.overlay {
  opacity: 0.5;
  background-color: white !important;
}

.instruction-content {
  display: flex !important;
  flex-direction: column !important;
  color: white;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  text-align: center;
}

.instruction-button {
  margin: auto;
  margin-top: 30px;
}

.text-block {
  color: white;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  text-align: center;
}

.sub-block {
  color: white;
  position: absolute;
  text-align: center;
  width: 450px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonTransition {
  transition: opacity 0.5s;
  opacity: 1;
}

.buttonHide {
  opacity: 0;
  pointer-events:none;
}

.guard-buttons {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0 !important;
  width: 100%;
  margin-bottom: 20px;
}

.greyOut {
  background-color: rgba(84, 84, 84, 0.70) !important;
  color: rgba(39,39,39, 0.74) !important;
}

.guard-title {
  font-size: 20px;
  text-align: center !important;
  margin: auto !important;
  display: block;
  position: absolute;
  top: 100px;
  width: 500px;
  left: 0;
  right: 0;
}